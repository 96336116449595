import React from 'react'
import '../App.css'
import { useNavigate } from 'react-router-dom'
import VisibilityIcon from '@mui/icons-material/Visibility';
export default function Cards({
  InstitutionName,
  logo,banner,
  CourseName,
  CourseDescription,
  CourseFee,
  CourseDurationy,
  CourseDurationm,
  CourseId,
  website,
  contact,
  email,
  institutionid,
  CourseFeeType
}) {
  
  const navigate = useNavigate(); // Use the hook to get the navigate function
  
  const navigateToCourseDetails = () => {
    navigate(`/shortcourse/course-details/${CourseId}`,{state:{course_id:CourseId,website,contact,email}}); // Correct way to navigate
  };
  return (
    <>
      <div className='dynamic-cards' onClick={navigateToCourseDetails}>
        <div className='card-institute'>
          <div className='institute-card-title'>
            <img src={logo ? logo : '/logo just.png'} className='institute-logo' alt='institute-logo'></img>
            <h1>{InstitutionName}</h1>
          </div>
          <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            <VisibilityIcon style={{ fontSize: '23px', color: 'rgb(104 100 100)' }} /> {/* Material-UI eye icon */}
            <p style={{ fontSize: '10px', color: '#333', margin: 0 }}>5&nbsp;views</p>
          </div>
        </div>
        <hr className='card-hr'></hr>
        <div className='card-category'>
          {/* <p className='card-p'>{CourseShortName}</p> */}
          <h1 className='card-title'>{CourseName}</h1>
          <p className='card-desc'>{CourseDescription}</p>
        </div>
        {/* <hr className='card-hr'></hr> */}
        <div className='card-footer'>
          <div className='course-fee'>
            <h1 className='footer-title'>Course Fee</h1>
            {CourseFee ? (<p>{"Rs. " + CourseFee+"/"+( CourseFeeType ? CourseFeeType.charAt(0).toUpperCase() + CourseFeeType.slice(1):"")}</p>) : (<p>N/A</p>)}
          </div>
          <div className='vl'></div>
          <div className='course-duration'>
            <h1 className='footer-title'>Course Duration</h1>
            <p>{CourseDurationy && CourseDurationy + (CourseDurationy > 1 ? (' Years') : (' Year'))} {CourseDurationy && CourseDurationm ? ("and ") : ''}{CourseDurationm && CourseDurationm + ' Months'} </p>
          </div>
        </div>
      </div>

    </>
  )
}